
//MENU
$(document).on('ready', function(){
    try{Typekit.load({ async: true });}catch(e){}

    $('.menu-container-icon').on('click', function(){
        console.log('click')
        $('.menu').toggleClass('is-active');
    });

        $(".owl-carousel").owlCarousel({
            nav:true,
            items:1,
            loop:true
        }); 
    if($(".instagram").length){
        var data_url = $('.instagram').data("url");
        var apiURL = data_url+'/api-instagram';
        $.get(apiURL, function(data)
        {
          $.each(data, function(i, data)
          {
              $(".instagram").append("<a  href='"+data.link+"' target='blank' data-title='"+data.caption.text+"'><img class='instagram-images' src='"+data.images.low_resolution.url+"' alt='"+data.caption.text+"'></a>");
              console.log(data);
          })
        });
    }
});
